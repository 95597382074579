<template>
  <div class="payment-success">
    <base-icon
      name="queue-check"
      size="xlarge"
    />
    <div class="payment-success__text-block">
      <p class="payment-success__title">
        {{ $t('checkout.title-success') }}
      </p>
      <p class="payment-success__text">
        {{ $t('checkout.text-success') }}
      </p>
    </div>
  </div>
</template>

<script>
import { WebSocketClient } from '@/services/websocket-client';

export default {
    components: {
        BaseIcon: () => import(/* webpackChunkName: "BaseIcon" */ '@RepoComponent/BaseIcon/BaseIcon'),
    },
    async mounted() {
        this.$store.dispatch('websocket_store/axn_websocketActionID', 'purchaseCheckoutOk');
        if (window.dataLayer) {
            window.dataLayer.push({event: 'checkoutCompleted'});
        }

        try {
            if (this.$route.query.client === 'flagship') {
                window.location.assign('voicemod:purchase_completed');
            }

            await WebSocketClient.connect({
                server: 'Kestrel',
                url: 'vmsd',
                actionType: 'purchaseCheckoutResponseOk',
            });
        } catch {
            console.log('Failed to open websocket connection');
        }
    },
};
</script>

<style src="./PaymentSuccessView.scss" lang="scss">
</style>
